import { Suspense } from "react";
import { createRoot } from "react-dom/client";
import App from "./components/App";

import "./semantic.css";
import "./styles.scss";

export const initialize = ({ element, language }) => {
  element.classList.add("documents-upload-root");

  const root = createRoot(element);
  root.render(
    <Suspense fallback={null}>
      <App language={language} />
    </Suspense>
  );
};
