import DocumentsContextProvider from '../context/DocumentsContext';
import { initialize } from '../i18n';
import DocumentsUpload from './DocumentsUpload';

let requestSent = false;

const App = ({ language = 'en' }) => {
  if (!requestSent) {
    requestSent = true;
    initialize(language);
  }

  return (
    <DocumentsContextProvider>
      <DocumentsUpload />
    </DocumentsContextProvider>
  );
};

export default App;
