import { useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import api from '../api';
import FileUpload from './FileUpload';
import { Button, Dimmer, Loader, Radio, Segment } from 'semantic-ui-react';
import { camelCaseToTitleCase, checkFileType } from '../utils';
import { DocumentsContext } from '../context/DocumentsContext';
import { DOCUMENTS_OPTIONS, PRODUCT_NAMES } from '../constants';

const token = new URLSearchParams(window.location.search).get('token');
const product = new URLSearchParams(window.location.search).get('product');

const isExistingDocument = (document) =>
DOCUMENTS_OPTIONS[product] && DOCUMENTS_OPTIONS[product].some((type) => 
type.value === document
);

const DocumentsUpload = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);
  const [errorMsg, setErrorMsg] = useState('');
  const [hasOtherDocuments, setHasOtherDocuments] = useState(false);
  const [success, setSuccess] = useState(false);
  const { files, setFiles, otherDocuments, setOtherDocuments } =
    useContext(DocumentsContext);
  
  const { t } = useTranslation();

  const fetchApplicant = useCallback(async () => {
    setLoading(true);
    try {
      const { data } = await api.documents.getApplicant(token, product);
      setData(data);
      const {
        document: { requiredDocuments, uploadedDocuments },
      } = data;
      if (uploadedDocuments) {
        uploadedDocuments.forEach((doc) => {
          if (requiredDocuments.includes(doc.name))
            setFiles((prev) => ({ ...prev, [doc.name]: doc }));
          else {
            !hasOtherDocuments && setHasOtherDocuments(true);
            setOtherDocuments((prev) => [...prev, doc]);
          }
        });
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }, [hasOtherDocuments, setFiles, setOtherDocuments]);

  useEffect(() => {
    !data && fetchApplicant();
  }, [data, fetchApplicant]);

  const handleUploadDocument = useCallback(
    async (document, file, fileType, index, other, name) => {
      const {
        applicant: { id, firstName, lastName },
        document: { id: documentId, product },
      } = data;
      const renamedFile = new File(
        [file],
        `${
          other ? file.name.split('.')[0] : camelCaseToTitleCase(document)
        } - ${lastName}, ${firstName}.${file.name.split('.')[1]}`
      );
      setLoading(true);
      try {
        const {
          data: { key },
        } = await api.documents.uploadDocument(
          id,
          product,
          document,
          renamedFile,
          fileType,
          documentId,
          other ? renamedFile.name : isExistingDocument(document) ? document : name,
        );
  
        if (other) {
          setOtherDocuments((prev) => {
            const updatedDocuments = [...prev];
            updatedDocuments[index] = { name: renamedFile.name, fileKey: key };
            return updatedDocuments;
          });
        } else {
          setFiles((prev) => ({
            ...prev,
            [isExistingDocument(document) ? document : name]: {
              name: isExistingDocument(document) ? document : name,
              fileKey: key,
            },
          }));
        }
  
        setErrorMsg('');
      } catch (error) {
        console.error(error);
        setErrorMsg(error.response.data.message || t('PLEASE_TRY_AGAIN'));
        if (other)
          setOtherDocuments((prev) => prev.filter((el, ind) => ind !== index));
        else
          setFiles((prev) => ({...prev, [document]: undefined}));
      }
        setLoading(false);
    },
    [data, setFiles, setOtherDocuments, t]
  );
  

  const handleRemoveDocument = useCallback(
    async (fileKey) => {
      const {
        applicant: { id },
        document: { id: documentId, product },
      } = data;
      

      if(!fileKey) {
        setOtherDocuments(prev => prev.slice(0,-1))
        return null;
      }

      setLoading(true);
      try {
        await api.documents.removeDocument(id, product, fileKey, documentId);
        setOtherDocuments((prev) =>
          prev.filter((el) => el.fileKey !== fileKey)
        );
      } catch (error) {
        console.error(error);
      }
      setLoading(false);
    },
    [data, setOtherDocuments]
  );

  const getDocumentName = useCallback((name)=>{
   return((DOCUMENTS_OPTIONS[product].find(document=>document.value===name))?.text)
  },[])
  
  const handleFinishUpload = useCallback(async () => {
    const {
      document: { id, product },
    } = data;
    setLoading(true);
    try {
      await api.documents.finishUpload(id, product);
      setSuccess(true);
    } catch (error) {
      setErrorMsg(t('PLEASE_TRY_AGAIN'));
      console.error(error);
    }
    setLoading(false);
  }, [data, t]);

  if (!token) {
    alert(t('INVALID_LINK'));
    return null;
  }
  
  if (data && new Date(data.document.expirationDate).getTime() < new Date().getTime()) {
    return (
      <div className="documents-upload-container expired">
        <h1>{t('UPLOAD_DOCUMENTS_HEADER')}</h1>
        <Segment>{t('LINK_EXPIRED')}</Segment>
      </div>
    );
  }

  if (!loading && !data) {
    return (
      <div className="documents-upload-container expired">
        <h1>{t('UPLOAD_DOCUMENTS_HEADER')}</h1>
        <Segment>{t('LINK_INVALID')}</Segment>
      </div>
    );
  }

  if (success || data?.document.finished) {
    return (
      <div className="documents-upload-container expired">
        <h1>{t('UPLOAD_DOCUMENTS_HEADER')}</h1>
        <Segment>
          {t('UPLOAD_SUCCESS')} {t('PRODUCT_NAME', { product: PRODUCT_NAMES[data?.document.product] })}
        </Segment>
      </div>
    );
  }

  return (
    <div className="documents-upload-container">
      <h1>{t('UPLOAD_DOCUMENTS_HEADER')}</h1>
      {loading && (
        <Dimmer active inverted>
          <Loader inverted>Loading</Loader>
        </Dimmer>
      )}
   {data?.document.requiredDocuments.map((document, ind) => {
    const documentName = isExistingDocument(document)
    ? document
    :'document';
      
      return (
        <Segment key={document + ind}>
          <FileUpload
            name={document}
            label={getDocumentName(document) || document}
            t={t}
            required
            isAgentOtherDocument={!isExistingDocument(document)}
            image={[
              'passport',
              'portrait',
              'firstChildPortrait',
              'secondChildPortrait',
            ].includes(document)}
            onFileChange={(file) =>
              handleUploadDocument(
                documentName,                 
                file,
                isExistingDocument(document) ?                         
                [
                  'passport',
                  'portrait',
                  'firstChildPortrait',
                  'secondChildPortrait',
                ].includes(document)
                  ? 'image'
                  : 'pdf'
                  : checkFileType(file.name),                      
                ind,                           
                false,
                !isExistingDocument(document) ? document : undefined,
              )
            }
          />
        </Segment>
      );
    })}
      <div className="field toggle">
        <label>{t('OTHER_DOCUMENTS')}</label>
        <Radio
          toggle
          checked={hasOtherDocuments}
          onChange={(e, { checked }) => setHasOtherDocuments(checked)}
        />
      </div>
      {hasOtherDocuments && (
        <div className="other-documents">
          {otherDocuments.map((doc, ind) => (
            <Segment key={ind} className="other-documents__item">
              <FileUpload
                name="document"
                label={`${t('OTHER_DOCUMENT')}${ind + 1}`}
                t={t}
                onFileChange={(file) =>
                  handleUploadDocument(
                    'document',
                    file,
                    checkFileType(file.name),
                    ind,
                    true
                  )
                }
                isOtherDocument
                index={ind}
              />
                <Button
                  content={t('REMOVE')}
                  onClick={() => handleRemoveDocument(doc?.fileKey)}
                />
            </Segment>
          ))}
          <Button
            content={t('ADD_NEW_DOCUMENT')}
            disabled={otherDocuments.some((el) => el === null)}
            onClick={() => setOtherDocuments((prev) => [...prev, null])}
          />
        </div>
      )}
      <Button
        content={t('UPLOAD_ALL_DOCUMENTS')}
        disabled={data?.document.requiredDocuments.some((doc) => !files[doc])}
        onClick={handleFinishUpload}
      />
      {errorMsg && <div className="sk-error-msg">{errorMsg}</div>}
    </div>
  );
};

export default DocumentsUpload;
