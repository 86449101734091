export const API_NAMES = {
	ETA: "eta",
	ESTA: "esta",
	DS160: "ds160",
	NZ: "nz",
	TRK: "trk",
	IND: "ind",
	AUS: "aus",
	INV: "inv_letter",
	SCHENGEN: "schengen",
	VN: "vn",
	DIVERSITY: "diversity",
	SG: 'sg',
	DS11: 'ds11',
	DS82: 'ds82',
	DS64: 'ds64',
	GE: 'ge',
	SL: 'sl',
	TSA: 'tsa',
	NEXUS: 'nexus',
	SENTRI: 'sentri',
  };
  export const PRODUCT_NAMES = {
	[API_NAMES.AUS]: "Australia eVisitor",
	[API_NAMES.DS160]: "U.S. Visa",
	[API_NAMES.ESTA]: "U.S. ESTA",
	[API_NAMES.ETA]: "Canada eTA",
	[API_NAMES.IND]: "India eTA",
	[API_NAMES.VN]: "Vietnam eTA",
	[API_NAMES.NZ]: "New Zealand eTA",
	[API_NAMES.TRK]: "Turkey eTA",
	[API_NAMES.SCHENGEN]: "Schengen Visa",
	[API_NAMES.DIVERSITY]: "Diversity Visa",
	[API_NAMES.SG]: "Singapore eTA",
	[API_NAMES.DS11]: "DS11 - Passport Service",
	[API_NAMES.DS82]: "DS82 - Passport Service",
	[API_NAMES.DS64]: "DS64 - Passport Service",
	[API_NAMES.GE]: "Global Entry Visa",
	[API_NAMES.SL]: "Sri Lanka ETA",
	[API_NAMES.TSA]: "TSA Pre-Check",
	[API_NAMES.NEXUS]: "NEXUS",
	[API_NAMES.SENTRI]: "SENTRI",
  };
  export const DOCUMENTS_OPTIONS = {
	  [API_NAMES.ESTA]: [{ text: "Passport", value: "passport" }],
	  [API_NAMES.IND]: [
		{ text: "Passport", value: "passport" },
		{ text: "Passport PDF", value: "passportPdf" },
		{ text: "Portrait", value: "portrait" },
		{
		  text: "Confirmation Letter G20",
		  value: "confirmationLetterG20",
		},
		{ text: "Hospital Letter", value: "letter" },
		{
		  text: "Organizer invitation",
		  value: "oragnizerInvitation",
		},
		{
		  text: "Political clearance",
		  value: "politicalClearance",
		},
		{ text: "Business card", value: "businessCard" },
		{ text: "Business invitation letter", value: "businessInvitationLetter" },
		{ text: "Host institute invitation", value: "hostInstituteInvitation" },
		{ text: "Sanction order", value: "sactionOrder" },
		{ text: "Courses synopsis", value: "synopsis" },
		{ text: "Sponsorship letter", value: "sponsorshipLetter" },
		{ text: "Government approval", value: "governmentApproval" },
		{ text: "Event clearance", value: "eventClearance" },
		{
		  text: "Indian shipping letter",
		  value: "indianShippingLetter",
		},
		{
		  text: "Continous discharge certificate",
		  value: "continuousDischargeCertificate",
		},
		{ text: "Organization concerned letter", value: "letterFromOrganizationConcerned" },
		{
		  text: "Institute, organization or hospital concerned letter",
		  value: "letterFromInstituteOrganizationHospitalConcerned",
		},
		{ text: "Sports invitation letter", value: "sportsInvitationLetter" },
		{ text: "Clearance for holding", value: "clearanceForHolding" },
		{ text: "Requisite clearance", value: "requisiteClearance" },
		{ text: "Support tax compliance", value: "supportTaxCompliance" },
	  ],
	  [API_NAMES.DS160]: [{ text: "Portrait", value: "portrait" }],
	  [API_NAMES.NZ]: [{ text: "Portrait", value: "portrait" }],
	  [API_NAMES.VN]: [
		{ text: "Passport", value: "passport" },
		{ text: "Portrait", value: "portrait" },
		{ text: "First child portrait", value: "firstChildPortrait" },
		{ text: "Second child portrait", value: "secondChildPortrait" },
	  ],
	  [API_NAMES.ETA]: [],
	  [API_NAMES.TRK]: [],
	  [API_NAMES.AUS]: [
		{ text: "Passport", value: "passport" },
	  ],
	  [API_NAMES.SCHENGEN]: [],
	  [API_NAMES.INV]: [],
	  [API_NAMES.DIVERSITY]: [{ text: "Portrait", value: "portrait" }],
	  [API_NAMES.SG]: [],
	  [API_NAMES.DS11]: [
		{ text: "Passport", value: "passport" },
	  ],
	  [API_NAMES.DS64]: [
		{ text: "Passport", value: "passport" },
	  ],
	  [API_NAMES.DS82]: [
		{ text: "Passport", value: "passport" },
	  ],
	  [API_NAMES.GE]: [],
	  [API_NAMES.SL]: [],
	  [API_NAMES.TSA]: [],
	  [API_NAMES.NEXUS]:[],
	  [API_NAMES.SENTRI]: [],
  };