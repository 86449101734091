import httpClient from "../httpClient";

const getApplicant = (token, product) => {
  return httpClient.get(`${product}/documents-upload`, { params: { token }});
};

const uploadDocument = (id, api, document, file, fileType, documentId, name) => {
  const formData = new FormData();
  formData.append("name", name);
  formData.append("fileName", document);
  formData.append("documentId", documentId);
  formData.append(fileType, file);
  return httpClient.post(`${api}/documents-upload/upload-${fileType}/applicant/${id}`, formData);
};

const removeDocument = (id, api, key, documentId) => {
  return httpClient.delete(`${api}/documents-upload/remove-file/${documentId}/applicant/${id}`, { params: { fileKey: key }})
}

const finishUpload = (documentId, api) => {
  return httpClient.post(`${api}/documents-upload/${documentId}/finish`)
}

const getFile = (key) => {
  return httpClient.get('ucc-files', { params: { ApiKey: 'c2ae9baa-0482-47b9-93e9-f17ea5ba58ab', key }})
}

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  getApplicant,
  uploadDocument,
  removeDocument,
  finishUpload,
  getFile,
};
