import React, { createContext, useState } from "react";


export const DocumentsContext = createContext(null);

const DocumentsContextProvider = ({ children }) => {
  const [files, setFiles] = useState({});
  const [otherDocuments, setOtherDocuments] = useState([]);

  return (
    <DocumentsContext.Provider
      value={{
        files,
        setFiles,
        otherDocuments,
        setOtherDocuments,
      }}
    >
      {children}
    </DocumentsContext.Provider>
  );
};

export default DocumentsContextProvider;
