import React, {
  useCallback,
  useContext,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Button, Dimmer, Loader } from 'semantic-ui-react';
import { DocumentsContext } from '../context/DocumentsContext';
import api from '../api';
import { byteArrayToUrl, checkFileType } from '../utils';

const FileUpload = (props) => {
  const {
    name,
    label,
    className,
    t,
    error,
    image,
    required,
    onFileChange,
    isOtherDocument,
    index,
    isAgentOtherDocument,
  } = props;
  const fileInputRef = useRef(null);
  const { files, otherDocuments } = useContext(DocumentsContext);
  const [loading, setLoading] = useState(false);

  const file = useMemo(
    () => (isOtherDocument ? otherDocuments[index] : files[name]),
    [files, index, isOtherDocument, name, otherDocuments]
  );

  const previewFile = useCallback(async () => {
    setLoading(true);
    try {
      const {
        data: {
          buffer: { data },
        },
      } = await api.documents.getFile(file.fileKey);
      const url = byteArrayToUrl(
        data,
        isOtherDocument
          ? checkFileType(file.name, true)
          : isAgentOtherDocument
          ? checkFileType(file.fileKey.replace('.enc', ''), true)
          : image
          ? 'image/jpeg'
          : 'application/pdf'
      );
      window.open(url, '_blank');
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
  }, [file, image, isAgentOtherDocument, isOtherDocument]);
  
  return (
    <div className={`field  ${error ? 'error' : ''} ${className}`}>
      <label className={`${required ? 'sk-label-text--required' : ''}`}>
        {label}
      </label>
      <div className="ui sk-file-upload" style={{ cursor: 'pointer' }}>
        <div>
          {loading && (
            <Dimmer active inverted>
              <Loader inverted>Loading</Loader>
            </Dimmer>
          )}
          {file && (
            <Button
              content={image ? t('VIEW_IMAGE') : t('VIEW')}
              onClick={previewFile}
            />
          )}
          {(!file || !isOtherDocument) && (
            <Button
              content={!file ? t('CHOOSE_FILE') : t('RE_UPLOAD')}
              onClick={() => fileInputRef.current.click()}
            />
          )}
        </div>
        <input
          ref={fileInputRef}
          type="file"
          accept={
            isOtherDocument || isAgentOtherDocument ? '.jpeg,.jpg,.pdf' : image ? '.jpeg,.jpg' : '.pdf' 
          }
          name={name}
          hidden
          onChange={(ev) => onFileChange(ev.target.files[0])}
        />
      </div>
      {error && <small className="helper">{error}</small>}
    </div>
  );
};

export default FileUpload;
