import i18n from "i18next";
import Backend from "i18next-http-backend";
import { initReactI18next } from "react-i18next";

const host = process.env.REACT_APP_HOST_URL;

export function initialize(language) {
  i18n
    .use(Backend)
    .use(initReactI18next)
    .init({
      lng: language,
      backend: {
        /* translation file path */
        loadPath: `${host}/assets/i18n/{{ns}}/{{lng}}.json`,
      },
      fallbackLng: "en",
      debug: false,
      /* can have multiple namespace, in case you want to divide a huge translation into smaller pieces and load them on demand */
      ns: ["translations"],
      defaultNS: "translations",
      // crossDomain: true,
      keySeparator: false,
      interpolation: {
        escapeValue: false,
        formatSeparator: ",",
      },
      react: {
        wait: true,
      },
    });
}

export default i18n;
