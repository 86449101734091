export const camelCaseToTitleCase = (text) => {
  const result = text.replace(/([A-Z])/g, " $1");
  const finalResult = result.charAt(0).toUpperCase() + result.slice(1);
  return finalResult;
}

export const checkFileType = (fileName, preview) => {
  const fileExtension = fileName.split('.').pop().toLowerCase();
  const imageExtensions = ['jpg', 'jpeg'];
  const pdfExtensions = ['pdf'];
  if (imageExtensions.includes(fileExtension)) return preview ? 'image/jpeg' : 'image';
  if (pdfExtensions.includes(fileExtension)) return preview ? 'application/pdf' : 'pdf';
  return null;
}

export function byteArrayToBlob(byteArray, type) {
  const blob = new Blob([new Uint8Array(byteArray)], { type })
  return blob
}

export function byteArrayToUrl(byteArray, type) {
  const url = URL.createObjectURL(byteArrayToBlob(byteArray, type))
  return url
}
